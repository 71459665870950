<template>
  <div>
    <KCrudLayout
        :filters.sync="filters"
        :search.sync="searchQuery"
    >
      <template #header>
        {{ $tc('puzzle.pageTitle', 2) }}
      </template>
      <template #view.list>
        <k-crud-table
            ref="table"
            :headers="crudHeaders"
            :search="searchQuery"
            :index-request="indexRequest"
            language-prefix="puzzle.fields"
            resource="puzzle"
            @click:edit="openUpdate"
            @click:row="$router.push({name: 'puzzle.show', params: {puzzleId: $event.id}})"
        >
          <template #item.isActive="{item}">
            <v-icon
                :key="item.statusId"
                :color="puzzleStatusColor(item.statusId)"
            >
              $circle
            </v-icon>
          </template>
          <template #item.dimensions="{item}">
            {{ item.width }}{{ $t('puzzle.other.centimeters') }} x {{ item.height }}{{ $t('puzzle.other.centimeters') }}
          </template>

          <template #item.isComplete="{item}">
            <v-tooltip
                v-if="!item.isComplete"
                top
            >
              <template v-slot:activator="{ on }">
                <v-icon
                    :key="item.isComplete"
                    :color="getIconColor(item.isComplete)"
                    v-on="on"
                >
                  $puzzlePiece
                </v-icon>
              </template>
              <span v-if="!item.isComplete">{{ $t('puzzle.other.missing', { 'count': item.numberOfMissingPieces }) }}</span>
            </v-tooltip>
            <v-icon
                v-else
                :key="item.isComplete"
                :color="getIconColor(item.isComplete)"
            >
              $check
            </v-icon>
          </template>
          <template #item.status="{item}">
            {{ statusOptions(item.statusId) }}
          </template>
        </k-crud-table>
      </template>
      <template #flow-actions>
        <v-btn
            color="primary"
            depressed
            tile
            @click="handleOpenCreateDialog"
        >
          {{ $t('actions.createResource', { resource: $tc('puzzle.title', 1) }) }}
        </v-btn>
        <PuzzleForm
            v-model="createDialog"
            :request="createRequest"
            :title="$tc('puzzle.title',1)"
            :values="createFormValues"
            @change="$refs.table.reload()"
        />
      </template>
    </KCrudLayout>
    <PuzzleForm
        v-model="updateDialog"
        :request="updateRequest"
        :title="$tc('puzzle.title',1)"
        :values="updateFormValues"
        is-update-form
        @change="$refs.table.reload()"
    />
  </div>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import querySyncMixin from '@/application/mixins/querySyncMixin.js';
import KCrudTable from '@/components/crud/KCrudTable.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { index, create, update, show } from '@/modules/puzzle/api/puzzle.js';
import { statuses } from '@/application/enums/statuses.ts';
import Puzzle from '@/application/models/Puzzle.js';
import PuzzleForm from '@/modules/puzzle/components/PuzzleForm.vue';
import dayjs from '@/plugins/dayjs.js';
import { getTranslatedEnumOptions } from '@/application/util/enum.js';

export default {
  name: 'PuzzleIndex',
  components: {
    PuzzleForm,
    KCrudTable,
    KCrudLayout,
  },
  mixins: [querySyncMixin],
  data() {
    return {
      searchQuery: '',
      options: {
        sortBy: ['createdAt'],
        sortDesc: [true],
      },
      updateDialog: false,
      createDialog: false,
      updateFormValues: new Puzzle(),
      createFormValues: new Puzzle(),
    };
  },
  computed: {
    crudHeaders() {
      return [
        {
          text: '',
          value: 'isActive',
          align: 'center',
          sortable: false,
          filterable: false,
          width: '1%',
          cellClass: 'no-table-separator',
        },
        {
          value: 'name',
          language: 'name',
        },
        {
          value: 'brand',
          language: 'brand',
        },
        {
          value: 'pieces',
          language: 'pieces',
        },
        {
          value: 'dimensions',
          language: 'dimensions',
        },
        {
          sortable: false,
          filterable: false,
          value: 'isComplete',
          language: 'complete',
        },
        {
          value: 'status',
          language: 'status',
        },
        {
          value: 'startDate',
          language: 'startDate',
        },
        {
          value: 'endDate',
          language: 'endDate',
        },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'puzzle.index' },
        text: this.$tc('puzzle.title', 2),
      },
    ]);
  },
  methods: {
    dayjs,
    puzzleStatusColor(statusId) {
      return statusId === statuses.completed ? 'green' : statusId === statuses.not_started ? 'red' : 'orange';
    },
    statusOptions(id) {
      return getTranslatedEnumOptions(statuses, 'puzzle.statuses')[id - 1].text;
    },
    handleOpenCreateDialog() {
      this.createFormValues = new Puzzle();
      this.createDialog = true;
    },
    async openUpdate(item) {
      this.updateFormValues = new Puzzle();
      const puzzleShowRequest = await show(item.id);
      this.updateFormValues.mapResponse(puzzleShowRequest.data.data);
      this.updateDialog = true;
    },
    updateRequest() {
      return update(this.updateFormValues);
    },
    createRequest() {
      return create(this.createFormValues);
    },
    indexRequest() {
      return index(...arguments);
    },
    getIconColor(isPositive) {
      return isPositive ? 'green' : 'red';
    },
  },
};
</script>
