var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('KCrudLayout',{attrs:{"filters":_vm.filters,"search":_vm.searchQuery},on:{"update:filters":function($event){_vm.filters=$event},"update:search":function($event){_vm.searchQuery=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$tc('puzzle.pageTitle', 2))+" ")]},proxy:true},{key:"view.list",fn:function(){return [_c('k-crud-table',{ref:"table",attrs:{"headers":_vm.crudHeaders,"search":_vm.searchQuery,"index-request":_vm.indexRequest,"language-prefix":"puzzle.fields","resource":"puzzle"},on:{"click:edit":_vm.openUpdate,"click:row":function($event){return _vm.$router.push({name: 'puzzle.show', params: {puzzleId: $event.id}})}},scopedSlots:_vm._u([{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{key:item.statusId,attrs:{"color":_vm.puzzleStatusColor(item.statusId)}},[_vm._v(" $circle ")])]}},{key:"item.dimensions",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.width)+_vm._s(_vm.$t('puzzle.other.centimeters'))+" x "+_vm._s(item.height)+_vm._s(_vm.$t('puzzle.other.centimeters'))+" ")]}},{key:"item.isComplete",fn:function(ref){
var item = ref.item;
return [(!item.isComplete)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({key:item.isComplete,attrs:{"color":_vm.getIconColor(item.isComplete)}},on),[_vm._v(" $puzzlePiece ")])]}}],null,true)},[(!item.isComplete)?_c('span',[_vm._v(_vm._s(_vm.$t('puzzle.other.missing', { 'count': item.numberOfMissingPieces })))]):_vm._e()]):_c('v-icon',{key:item.isComplete,attrs:{"color":_vm.getIconColor(item.isComplete)}},[_vm._v(" $check ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.statusOptions(item.statusId))+" ")]}}])})]},proxy:true},{key:"flow-actions",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","depressed":"","tile":""},on:{"click":_vm.handleOpenCreateDialog}},[_vm._v(" "+_vm._s(_vm.$t('actions.createResource', { resource: _vm.$tc('puzzle.title', 1) }))+" ")]),_c('PuzzleForm',{attrs:{"request":_vm.createRequest,"title":_vm.$tc('puzzle.title',1),"values":_vm.createFormValues},on:{"change":function($event){return _vm.$refs.table.reload()}},model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}})]},proxy:true}])}),_c('PuzzleForm',{attrs:{"request":_vm.updateRequest,"title":_vm.$tc('puzzle.title',1),"values":_vm.updateFormValues,"is-update-form":""},on:{"change":function($event){return _vm.$refs.table.reload()}},model:{value:(_vm.updateDialog),callback:function ($$v) {_vm.updateDialog=$$v},expression:"updateDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }