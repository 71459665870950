/**
 * This is a mixin for a Vue component / often a *Filterbar
 * To use this mixin, you need to add it as a mixin to your component
 *
 * Example:
 export default {
      mixins: [querySyncMixin],
      components: { ... },
      data() {
        return {
          filters: {},
          ...
        };
      },
      ...
    };
 * If you wish to have a default set of filters, make sure to set
 * the filters property.
 *
 * The mixin exposes "filters" to the component it is included.
 * Thus, in the event of using a KCrudLayout, you can set it as following
 <KCrudLayout :filters.sync="filters">
 * It will now included filters
 *
 * WHEN WORKING WITH ARRAYS
 * On the API method, you need to make sure the API always gets an array.
 * The URL will show `foo=1&foo=2&foo=3` -> this will be an array named foo
 * with the content [1,2,3]. When you change to select one item, the url will
 * show `foo=1`. QuerySync will transform this to an integer (foo = 1) instead
 * of an array. The API might not accept this.
 *
 * Possible fix (example on getPaginated):
 * Transform the params ->
 *
 export const index = async (page, perPage, search, sortBy, descending, params) => {
      params = {
        ...!!params.invoiceStatuses && { foo: Array.isArray(params.foo) ? params.foo : [params.foo] },
      };
      return await getPaginated(`invoice`, page, perPage, search, sortBy, descending, params);
    }
 */
import { isEmpty } from '@/application/util/isEmpty.js';
import equal from 'deep-equal';

export default {
  data() {
    return {
      _isUpdatingQuerySync: false,
      filters: {},
    };
  },
  watch: {
    filters: {
      handler(queries) {
        if (!this._isUpdatingQuerySync && !this._currentSyncQueriesEqual(queries)) {
          this._isUpdatingQuerySync = true;
          this._updateSyncQuery(queries);
        }
      },
    },
  },
  computed: {
    _querySyncParams() {
      return this.$route?.query && this._queryToFilters(this.$route?.query);
    },
  },
  created() {
    this.filters = !!this._querySyncParams && !!Object.values(this._querySyncParams)[0] ? this._querySyncParams : this.filters;
  },
  methods: {
    _convertToTrueValues(val) {
      /*
       * I cannot imagine that we are going to use actual Objects in the query params.
       * Thus, Objects are not covered.
       */
      if (Array.isArray(val)) return val.map(val => this._convertToTrueValues(val));
      if (isFinite(val)) return Number(val);
      return val;
    },
    _queryToFilters(queries) {
      return Object.fromEntries(
        Object.entries(queries).map(([key, val]) => [key, this._convertToTrueValues(val)]),
      );
    },
    async _updateSyncQuery(query) {
      query = Object.fromEntries(
        Object.entries(query).filter(([key, val]) => !isEmpty(val) && [key, val]),
      );
      try {
        await this.$router.replace({ query });
      } catch {
        /*
          * TODO: still shows error: Avoided redundant navigation to current location
          *  nextTick reduces this error, but does not 'fix' it entirely
        */
      } finally {
        this.$nextTick(() => this._isUpdatingQuerySync = false);
      }
    },
    _currentSyncQueriesEqual(queries) {
      return equal(this._querySyncParams, queries);
    },
  },
};
